import { v4 as uuidv4 } from 'uuid'

export const isMultipleCarrierAcountsEnabled = false

// Initial states
export const initialPaymentTerms = {
    paymentTermsSelection: 'prepaid',
    carriers: [ '' ],
    accountNumbers: [ '' ],
    uuidKeys: [ uuidv4() ],
}
export const carrierCodeMapping: Record<string, string> = {
    ['Manitoulin']: 'Manitoulin',
    ['GLS Canada']: 'GLS Canada',
    ['Purolator']: 'PRLA',
    ['Fastfrate']: 'CFFO',
    ['Canpar']: 'CNPR',
    ['Day & Ross Commerce']: 'DAYR',
    ['Fedex']: 'FXFE',
    ['Xpol']: 'XPOL',
    ['UPS']: 'UPS',
    ['Echo']: 'ECHO',
    ['Holland']: 'HMES',
    ['SynergieCanada']: 'Synergie Canada',
    ['Wwex']: 'WWEX',
    ['Canada Post']: 'Canada Post',
    ['AbfFreight']: 'ABFS',
    ['DHL']: 'DHL',
    ['Lazr']: 'Lazr Freight',
    ['SteelesTransfer']: 'SteelesTransfer',
    ['Labranche']: 'Labranche',
    ['WesternExpress']: 'WXSM',
    ['NewPenn']: 'NPME',
    ['JpsLogistique']: 'JPSE',
    ['ForwardAir']: 'FWDN',
    ['ViaExpress']: 'Via Express',
    ['EighteenWheels']: '18Wheels',
    ['Rosenau']: 'Rosenau',
    ['YrcFreight']: 'RDWY',
    ['RcTransport']: 'RC',
    ['Remco']: 'Remco',
    ['Teams']: 'TMBM',
    ['Rona']: 'Rona',
    ['Polaris']: 'POLT',
    ['Guilbault']: 'GBIT',
    ['Valois']: 'Valois',
    ['Tforce']: 'QXTI',
    ['Bourret']: 'TPBO',
    ['Speedy']: 'SZTG',
    ['Apex']: 'AXME',
    ['Chr']: 'RBTW',
    ['Transx']: 'TransX Intermodal',
    ['NmTransfer']: 'NMTF',
    ['Reddaway']: 'RETL',
    ['Gariepy']: 'Gariepy',
    ['Minimax']: 'MNME',
    ['JaysTransportation']: 'JMSM',
    ['Vitran']: 'Vitran',
    ['Midland']: 'Midland',
    ['Estes']: 'EXLA',
    ['WesternCanadaExpress']: 'Western Canada Express Intermodal',
    ['Transkid']: 'Transkid',
    ['OldDominionFreight']: 'ODFL',
    ['Rci']: 'Transport RCI - EDI',
    ['IntegratedTransportationSolution']: 'ITSL',
    ['Nationex']: 'NINP',
    ['Ward']: 'WARD',
    ['Robert']: 'ROB1',
    ['TstOverland']: 'OVLD',
    ['Transplus']: 'Transplus',
    ['InterNord']: 'Inter-Nord',
    ['Normandin']: 'Normandin',
    ['Cascades']: 'CTNP',
}

export const freightCarriers = [
    'FXFE',
    'EXLA',
    'DAYR',
    'GLS Canada',
    'ABFS',
    'XPOL',
    'OVLD',
    'POLT',
    'ODFL',
    'NPME',
    'HMES',
    'RETL',
    'Synergie Canada',
    'CFFO',
    'AXME',
    'Western Canada Express Intermodal',
    'Vitran',
    'MNME',
    'Labranche',
    'Inter-Nord',
    'Manitoulin',
    'TPBO',
    'GBIT',
    'Normandin',
    'QXTI',
    'Midland',
    'TransX',
    'Via Express',
    'Transplus',
    'Transkid',
    'SteelesTransfer',
    'WWEX',
    'ECHO',
    'AndMore',
]

export const parcelCarriers = [
    'UPS',
    'FXFE',
    'PRLA',
    'Canada Post',
    'GLS Canada',
    'CNPR',
    'AndMore',
]


/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'
import {
    Checkbox as MuiCheckbox,
    CheckboxProps,
    Radio as MuiRadio,
    RadioProps,
    ButtonBase,
    ButtonBaseProps,
    IconButton as MuiIconButton,
    TypographyProps,
    Typography,
    Grid as MuiGrid,
} from '@material-ui/core'

import React from 'react'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledContainer = styled.div<{
    $width?: string | number
    $height?: string | number
    $unexpandedHeight?: string | number
    $canExpand?: boolean
}>`
    ${(props): string => props.$width ? `width: ${props.$width};` : ''}
    ${(props): string => props.$height ? `height: ${props.$height};` : ''}
    overflow: hidden;
    position: relative;
    transition: width 300ms ease-out 0ms, height 300ms ease-out 0ms;
    border: 1px solid ${(props): string => props.theme.palette.neutral400.main};
    border-radius: 4px;
    :hover {
        border-color: ${(props): string => props.theme.palette.black.main};
        box-shadow: 0px 4px 20px ${(props): string => transparentize(0.9, props.theme.palette.accentSecondary700.main)};
    }
    :has(input:checked){
        border-color: ${(props): string => props.theme.palette.accentPrimary600.main};

        ::before {
            border-bottom-width: 4px;
            border-color: ${(props): string => props.theme.palette.accentPrimary600.main};
        }
        border-bottom-width: 1px;
    }

    ::before {
        content: "";
        position: absolute;
        left: -1px;
        right: -1px;
        border-width: 0px;
        z-index: 1;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: -1px;
        border-style: solid;
        border-color: ${(props): string => props.theme.palette.black.main};
        transition: all 100ms ease-out 0ms;
    }
    :hover::before {
        border-bottom-width: 4px;
    }
    ${(props): string => props.$canExpand ? `
        ::after {
            content: "";
            position: absolute;
            left: 0px;
            right: 0px;
            border-bottom-width: 0px;
            border-top-width: 0px;

            ${props.$unexpandedHeight ? `top: calc(${props.$unexpandedHeight} - 5px);` : ''}
            border-style: solid;

            width: 0%;
            transition: all 300ms ease-out 0ms;
        }
        &.lzr-selection-card-container.lzr-selection-card-container-expanded {
            ::after {
                border-color: ${props.theme.palette.accentPrimary600.main};
                border-radius: 0;
                width: 100%;
                border-bottom-width: 4px;
            }
            :has(input:checked){
                ::before {
                    border-bottom-width: 0px;
                }
                :hover {
                    box-shadow: unset;
                }
            }
        }
        &.lzr-selection-card-container.lzr-selection-card-container-expanded.lzr-selection-card-container-error {
            ::after {
                border-color: ${props.theme.palette.error900.main};
                :has(input:checked){
                    border-color: ${props.theme.palette.error900.main};
                }
            }
        }
        `
        :
        ''}
    &.lzr-selection-card-container-expanded.lzr-selection-card-container-error {
        border-color: ${(props): string => props.theme.palette.error900.main};
        :has(input:checked){
            border-color: ${(props): string => props.theme.palette.error900.main};
        }
    }
`

export const StyledLabel:
React.FC<ButtonBaseProps & { component?: string
    $unclickable?: boolean
    $helpers?: boolean
    $unexpandedHeight?: string | number
    $expanded?: boolean
}> = styled(ButtonBase)
<ButtonBaseProps & { component?: string
    $unclickable?: boolean
    $helpers?: boolean
    $unexpandedHeight?: string | number
    $expanded?: boolean
}>`
    ${(props): number | string => !props.$expanded && props.$unexpandedHeight ? `height: ${props.$unexpandedHeight};` : ''}
    display: block;
    padding: ${(props): string => `${props.theme.spacing(4)}px
        ${props.theme.spacing(10)}px
        ${props.theme.spacing(4)}px
        ${props.theme.spacing(4)}px`};
    background: ${(props): number => props.theme.palette.white.main};
    width: 100%;
    [class^="MuiTouchRipple-root"] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    :has(input:checked){
        background: ${(props): string => transparentize(0.5, props.theme.palette.accentPrimary50.main)};
        :hover {
            background: ${(props): string => transparentize(0.75, props.theme.palette.accentPrimary50.main)};
        }
    }
    &.lzr-selection-card.lzr-selection-card-expanded {
        :has(input:checked){
            :hover {
                background: ${(props): string => transparentize(0.5, props.theme.palette.accentPrimary50.main)};
            }
        }
    }
    &.lzr-selection-card-expanded.lzr-selection-card-error {
        background: ${(props): number => props.theme.palette.error50.main};
        :has(input:checked){
            background: ${(props): number => props.theme.palette.error50.main};
            :hover {
                background: ${(props): string => props.theme.palette.error50.main};
            }
        }
    }
    transition: all 300ms ease-out 0ms;

    ${(props): string => props.$unclickable ? 'cursor: default;' : ''}

    .lzr-icon {
        color: ${(props): string => props.theme.palette.black.main};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
    }
    .lzr-title {
        color: ${(props): string => props.theme.palette.black.main};
    }
    .lzr-description {
        min-height: 54px;
        color: ${(props): string => props.theme.palette.neutral700.main};
        ${(props): string => props.$helpers ? 'margin-bottom: 29px;' : ''}
    }
    .lzr-helper-text {
        color: ${(props): string => props.theme.palette.neutral500.main};
    }
    .lzr-helper-text-icon {
        color: ${(props): string => props.theme.palette.neutral400.main};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
    }
    .lzr-icon.lzr-icon-checked {
        color: ${(props): string => props.theme.palette.accentPrimary900.main};
    }
    .lzr-icon.lzr-icon-error {
        color: ${(props): string => props.theme.palette.error900.main};
    }
    .lzr-icon.lzr-icon-error {
        color: ${(props): string => props.theme.palette.black.main};
    }
    .lzr-title.lzr-title-checked {
        color: ${(props): string => props.theme.palette.accentPrimary900.main};
    }
    .lzr-title.lzr-title-error  {
        color: ${(props): string => props.theme.palette.black.main};
    }
    .lzr-description.lzr-description-checked {
        color: ${(props): string => props.theme.palette.accentPrimary900.main};
    }
    .lzr-helper-text.lzr-helper-text-checked {
        color: ${(props): string => props.theme.palette.accentPrimary900.main};
    }
    .lzr-helper-text-icon.lzr-helper-text-icon-checked {
        color: ${(props): string => props.theme.palette.accentPrimary900.main};
    }
    .lzr-description.lzr-description-error {
        color: ${(props): string => props.theme.palette.neutral700.main};
    }
    .lzr-helper-text.lzr-helper-text-error {
        color: ${(props): string => props.theme.palette.neutral500.main};
    }
    .lzr-helper-text-icon.lzr-helper-text-icon-error {
        color: ${(props): string => props.theme.palette.neutral400.main};
    }
`

export const StyledMuiCheckbox: React.FC<CheckboxProps> = styled(MuiCheckbox) <CheckboxProps>`
    display: none;
`

export const StyledMuiRadio: React.FC<RadioProps> = styled(MuiRadio) <RadioProps>`
    display: none;
`

export const StyledCloseIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    color: ${(props): string => props.theme.palette.text.primary};
`

export const StyledCloseIconContainer = styled(MuiIconButton)`
    width: 24px;
    height: 24px;
    right: 12px;
    position: absolute;
    top: 12px;
    z-index: 1;
`
export const StyledCheckIcon = styled(FontAwesomeIcon)`
    font-size: 1.42857143rem;
`

export const StyledUncheckedIcon = styled.div`
    width: 24px;
    height: 24px;
    right: 12px;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledCheckIconContainer = styled(StyledUncheckedIcon)`
    color: ${(props): string => props.theme.palette.accentPrimary600.main};
    &.lzr-check-icon-error {
        color: ${(props): string => props.theme.palette.error900.main};
    }
`
export const ExpandedContent = styled.div`
    background: ${(props): string => props.theme.palette.white.main};

    &.lzr-expanded-content.lzr-expanded-content-expanded {}
`
export const StyledTypographyH6: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.143rem;
    font-weight: 500;
    line-height: 140%;
`
export const StyledTypographySubtitle1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 140%;
`
export const StyledTypographyBody2 = styled(Typography)<{ component?: React.ElementType<React.HTMLAttributes<HTMLElement>> }>`
    font-size: 0.857rem;
    font-weight: 400;
    line-height: 140%;
`

export const AbsoluteGrid = styled(MuiGrid)`
    position: absolute;
    bottom: 16px;
    left: 16px;
`

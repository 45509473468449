/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './PackageTypeAndDescription.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['PLT']: 'Palette',
    ['BAG']: 'Sac',
    ['BALE']: 'Balle',
    ['BOX']: 'Boîte',
    ['BUCKET']: 'Seau',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Paquet',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Caisse',
    ['COIL']: 'Bobine',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylindre',
    ['DRUM']: 'Fût',
    ['PIECES']: 'Pièces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Rouleau',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Bac',
    ['OTHER']: 'Autres services de fret',
    ['FORKLIFT']: 'Chariot élévateur',
    ['PARCEL']: 'Colis',
    ['PAK']: 'Pak',
    ['ENVELOPE']: 'Enveloppe',

    // Descriptions à recevoir
    ['Description for PLT']:'Description de palette',
    ['Description for BAG']:'Description de sac',
    ['Description for BALE']:'Description de balle',
    ['Description for BOX']: 'Description de boîte',
    ['Description for BUCKET']:'Description de seau',
    ['Description for PAIL']:'Description de pail',
    ['Description for BUNDLE']:'Description de paquet',
    ['Description for CAN']:'Description de can',
    ['Description for CARTON']:'Description de carton',
    ['Description for CASE']:'Description de caisse',
    ['Description for COIL']:'Description de bobine',
    ['Description for CRATE']:'Description de crate',
    ['Description for CYLINDER']:'Description de cylindre',
    ['Description for DRUM']:'Description de fût',
    ['Description for PIECES']:'Description de pièces',
    ['Description for REEL']:'Description de reel',
    ['Description for ROLL']:'Description de rouleau',
    ['Description for TUBE']:'Description de tube',
    ['Description for TOTE']:'Description de bac',
    ['Description for OTHER']:'Description de autre',
    ['Description for FORKLIFT']:'Description de Chariot élévateur',
    ['Description for PARCEL']: 'Description de colis',
    ['Description for PAK']: 'Options d\'emballage spécifiques à chaque transporteur et fournies par ceux-ci.',
    ['Description for ENVELOPE']:'Description d\'enveloppe',
}))()

import {
    UuidV4,
    DimensionUnit,
    PackageType,
    RfqStatus,
    ShipmentIdentifierType,
    ShipmentStatus,
    TrackingUpdateStatus,
    WeightUnit,
    ServiceType,
} from '@lazr/openapi-client'
import { ShipmentDocumentType } from '@lazr/enums'

export interface OrderInfoTrackingUpdate {
    id: UuidV4
    createdAt: string
    trackingUpdateTime: string
    status: TrackingUpdateStatus
    statusMessage: string
    city: string
    state: string
}

export interface OrderInfoShipmentIdentifier {
    type: ShipmentIdentifierType
    value: string
}

export interface OrderInfoShipment {
    status: ShipmentStatus
    createdAt: string
    estimatedDeliveryDate: string
}

export interface OrderInfoHandlingUnit {
    quantity: number
    pieces: number
    length: number
    lengthUnit: DimensionUnit
    width: number
    widthUnit: DimensionUnit
    height: number
    heightUnit: DimensionUnit
    totalWeight: number
    totalWeightUnit: WeightUnit
    packageType?: {
        code: PackageType
        singularName: string
        pluralName: string
    }
    description: string
    poNumber: string | null
}

interface OrderInfoQuote {
    id: UuidV4
    carrierDisplayName: string
    serviceLevel: string
}

export interface OrderInfoRfq {
    selectedQuoteId: UuidV4
    quotes: OrderInfoQuote[]
    status: {
        code: RfqStatus
        name: string
    }
    quote: {
        carrierDisplayName: string | undefined
        serviceLevel: string | undefined
    } | null
    bookedAt: string
}

export interface TrackingInfoAddress {
    streetAddress: string
    streetAddress2: string
    city: string
    state: string
    country: string
    postalCode: string
    poNumber: string
}

export interface TrackingInfoProps {
    number: string
    tracking: {
        createdAt: string
        bookedAt: string
        shipment: OrderInfoShipment | null
        status: RfqStatus
        updates: OrderInfoTrackingUpdate[]
    }
    origin: TrackingInfoAddress
    destination: TrackingInfoAddress
    handlingUnits: OrderInfoHandlingUnit[]
    carrierInfo: {
        carrier: string | null
        serviceLevel: string | null
        idValue: string | null
        trackingServiceType: ServiceType | null
        trackingUrl: string | null
        trackingShipmentIdentifierType: ShipmentIdentifierType | null
    }
    identifiers: {
        type: ShipmentIdentifierType
        value: string
    }[]
    shipmentDocumentType: ShipmentDocumentType | null
}

export class TrackingInfo {
    public number: string
    public tracking: {
        createdAt: string
        bookedAt: string
        shipment: OrderInfoShipment | null
        status: RfqStatus
        updates: OrderInfoTrackingUpdate[]
    }
    public origin: TrackingInfoAddress
    public destination: TrackingInfoAddress
    public handlingUnits: OrderInfoHandlingUnit[]
    public carrierInfo: {
        carrier: string | null
        serviceLevel: string | null
        idValue: string | null
        trackingServiceType: ServiceType | null
        trackingUrl: string | null
        trackingShipmentIdentifierType: ShipmentIdentifierType | null
    }
    public identifiers: {
        type: ShipmentIdentifierType
        value: string
    }[]
    public shipmentDocumentType: ShipmentDocumentType | null

    constructor (props: TrackingInfoProps) {
        this.number = props.number
        this.tracking = props.tracking
        this.origin = props.origin
        this.destination = props.destination
        this.handlingUnits = props.handlingUnits
        this.carrierInfo = props.carrierInfo
        this.identifiers = props.identifiers
        this.shipmentDocumentType = props.shipmentDocumentType
    }
}

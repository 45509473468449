/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['PLT']: 'Pallet',
    ['BAG']: 'Bag',
    ['BALE']: 'Bale',
    ['BOX']: 'Box',
    ['BUCKET']: 'Bucket',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Bundle',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Case',
    ['COIL']: 'Coil',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylinder',
    ['DRUM']: 'Drum',
    ['PIECES']: 'Pieces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Roll',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Tote',
    ['OTHER']: 'Other Freight services',
    ['FORKLIFT']: 'Forklift',
    ['PARCEL']: 'Parcel',
    ['PAK']: 'Pak',
    ['ENVELOPE']: 'Envelope',

    // Descriptions to come
    ['Description for PLT']: 'Pallet description',
    ['Description for BAG']: 'Bag description',
    ['Description for BALE']: 'Bale description',
    ['Description for BOX']: 'Box description',
    ['Description for BUCKET']: 'Bucket description',
    ['Description for PAIL']: 'Pail description',
    ['Description for BUNDLE']: 'Bundle description',
    ['Description for CAN']: 'Can description',
    ['Description for CARTON']: 'Carton description',
    ['Description for CASE']: 'Case description',
    ['Description for COIL']: 'Coil description',
    ['Description for CRATE']: 'Crate description',
    ['Description for CYLINDER']: 'Cylinder description',
    ['Description for DRUM']: 'Drum description',
    ['Description for PIECES']: 'Pieces description',
    ['Description for REEL']: 'Reel description',
    ['Description for ROLL']: 'Roll description',
    ['Description for TUBE']: 'Tube description',
    ['Description for TOTE']: 'Tote description',
    ['Description for OTHER']: 'Other description',
    ['Description for FORKLIFT']: 'Forklift description',
    ['Description for PARCEL']: 'Parcel description',
    ['Description for PAK']: 'Packaging options provided and specific to each carriers.',
    ['Description for ENVELOPE']: 'Envelope description',
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrepareSearchRequest } from '../models/PrepareSearchRequest';
import type { SearchRequestData } from '../models/SearchRequestData';
import type { SetCoverageRequest } from '../models/SetCoverageRequest';
import type { SuccessResponse_GetBatchShippingStatusResponse_ } from '../models/SuccessResponse_GetBatchShippingStatusResponse_';
import type { SuccessResponse_GetPreparedSearchByIdResponse_ } from '../models/SuccessResponse_GetPreparedSearchByIdResponse_';
import type { SuccessResponse_GetPreparedSecureSearchByIdResponse_ } from '../models/SuccessResponse_GetPreparedSecureSearchByIdResponse_';
import type { SuccessResponse_GetSearchByIdResponse_ } from '../models/SuccessResponse_GetSearchByIdResponse_';
import type { SuccessResponse_PrepareSearchResponse_ } from '../models/SuccessResponse_PrepareSearchResponse_';
import type { SuccessResponse_SearchResponse_ } from '../models/SuccessResponse_SearchResponse_';
import type { SuccessResponse_SetCoverageResponse_ } from '../models/SuccessResponse_SetCoverageResponse_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class SearchService {

    /**
     * Prepare the search. It will be used to create an order with all the informations needed before the actuel search.
     * @param requestBody The data
     * @returns SuccessResponse_PrepareSearchResponse_ OK
     * @throws ApiError
     */
    public static async prepareSearch(
        requestBody: PrepareSearchRequest,
    ): Promise<SuccessResponse_PrepareSearchResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/prepare-search`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @param startSearchQuote
     * @param forceInsertSearchQuote
     * @param clone
     * @param synchronous
     * @param carrierIds
     * @param timeout
     * @returns SuccessResponse_SearchResponse_
     * @throws ApiError
     */
    public static async search(
        requestBody: SearchRequestData,
        startSearchQuote: boolean = true,
        forceInsertSearchQuote: boolean = false,
        clone: boolean = false,
        synchronous: boolean = false,
        carrierIds?: Array<string>,
        timeout?: number,
    ): Promise<SuccessResponse_SearchResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/search`,
            query: {
                'startSearchQuote': startSearchQuote,
                'forceInsertSearchQuote': forceInsertSearchQuote,
                'clone': clone,
                'synchronous': synchronous,
                'carrierIds': carrierIds,
                'timeout': timeout,
            },
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Set the coverage information for this search
     * @param requestBody The data
     * @returns SuccessResponse_SetCoverageResponse_ OK
     * @throws ApiError
     */
    public static async setCoverage(
        requestBody: SetCoverageRequest,
    ): Promise<SuccessResponse_SetCoverageResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/set-search-coverage`,
            body: requestBody,
            errors: {
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a batch shipping status by batch ID
     * @param batchId The search ID
     * @returns SuccessResponse_GetBatchShippingStatusResponse_ OK
     * @throws ApiError
     */
    public static async getBatchShippingStatus(
        batchId: UuidV4,
    ): Promise<SuccessResponse_GetBatchShippingStatusResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/batch-shipping-status/${batchId}`,
            errors: {
                400: `Bad request`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get prepared search by its id
     * @param preparedSearchId The prepared search id
     * @param clone The allow to get other status
     * @returns SuccessResponse_GetPreparedSearchByIdResponse_ OK
     * @throws ApiError
     */
    public static async getPreparedSearchById(
        preparedSearchId: string,
        clone: boolean = false,
    ): Promise<SuccessResponse_GetPreparedSearchByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/prepared-search-by-id/${preparedSearchId}`,
            query: {
                'clone': clone,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get prepared search by its id
     * @param preparedSearchId The prepared search id
     * @param clone The allow to get other status
     * @returns SuccessResponse_GetPreparedSecureSearchByIdResponse_ OK
     * @throws ApiError
     */
    public static async getSecurePreparedSearchById(
        preparedSearchId: string,
        clone: boolean = false,
    ): Promise<SuccessResponse_GetPreparedSecureSearchByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/prepared-secure-search-by-id/${preparedSearchId}`,
            query: {
                'clone': clone,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a search by its ID
     * @param searchId The search ID
     * @param includeCoverages
     * @returns SuccessResponse_GetSearchByIdResponse_ OK
     * @throws ApiError
     */
    public static async getSearchById(
        searchId: UuidV4,
        includeCoverages?: boolean,
    ): Promise<SuccessResponse_GetSearchByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/search-by-id/${searchId}`,
            query: {
                'includeCoverages': includeCoverages,
            },
            errors: {
                400: `Bad request`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}